import React, { Component } from "react";
import Link from "../../../Link.js";
import commonFunction from "../../../lib/utils/common.js";
import configUrl from "../../../../config/config.json";
import { logout } from "../../../lib/logout.js";
import { checksession } from "../../../lib/checksession.js";
import chat from "../../../lib/chat.js";
import FBPixCodeScript from "../../../analytics/FacebookPixelCode.js";
import Analytics from "../../../analytics/index.js";
import UploadLogo from "store/uploadLogo";
import MoreMenu from "../../Moremenu/index.js";
import GoogleTranslate from "../../googleTranslate/custom.js";

class Header5 extends Component {
  constructor() {
    super();
    this.state = {
      isShowPopup: false,
      userFirststname: "",
      userType: "",
      openInitialPopup: false,
      onHoverSupport: false,
      transform: null,
      scrolling: false,
      openSupportPopup: false,
      openCurrencyPopup: false,
      activeLink: "",
      openMorePopup: false,
      pathname: "",
      isTranslationModal: false,
      language: 'en'
    };
    this.style = {
      color: "red",
    };
  }

  componentDidMount() {
    let url = window.location.pathname;
    if(this.props?.data?.siteData?.site?.allow_multilingual) {
      let googtransCookie = commonFunction.getCookie("googtrans");
      let language = googtransCookie != "" ? googtransCookie.split("/")[2] : 'en';
      this.setState({ language });
    }
    this.setState({ pathname: url });
    if (url == "/" || url.includes("holiday") || url.includes("holidays")) {
      this.setState({ activeLink: "Holidays" });
    }
    if (url.includes("flight") || url.includes("flights")) {
      this.setState({ activeLink: "Flights" });
    }
    if (url.includes("hotel") || url.includes("hotels")) {
      this.setState({ activeLink: "Hotels" });
    }
    if (url.includes("activity") || url.includes("activities")) {
      this.setState({ activeLink: "Activities" });
    }
    //check if varification token is there
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleCalendarClickOutside);
    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);

    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        userType: this.props?.data?.user?.type,
      });
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
    // this.checkSessionApi()
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleCalendarClickOutside);
    window.removeEventListener("scroll", this.handleScroll);
  };

  setCalendarWrapperRef = (node) => {
    this.calendarWrapperRef = node;
  };

  setSupportWrapperRef = (node) => {
    this.supportWrapperRef = node;
  };
  setMoreWrapperRef = (node) => {
    this.MoreWrapperRef = node;
  };

  setCurrencyWrapperRef = (node) => {
    this.currencyWrapperRef = node;
  };

  handleScroll = (event) => {
    // console.log("clicked");
    if (window.scrollY <= 250 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
      // console.log("false");
    } else if (window.scrollY > 250 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
      // console.log("true");
    }
  };

  handleCalendarClickOutside = (event) => {
    if (
      !this.calendarWrapperRef ||
      !this.calendarWrapperRef.contains(event.target)
    ) {
      this.setState({ openInitialPopup: false });
    }

    if (
      !this.supportWrapperRef ||
      !this.supportWrapperRef.contains(event.target)
    ) {
      this.setState({ openSupportPopup: false });
    }
    if (!this.MoreWrapperRef || !this.MoreWrapperRef.contains(event.target)) {
      this.setState({ openMorePopup: false });
    }

    if (
      !this.currencyWrapperRef ||
      !this.currencyWrapperRef.contains(event.target)
    ) {
      this.setState({ openCurrencyPopup: false });
    }
  };

  checkSessionApi = () => {
    if (
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      this?.props?.data?.sessionData?.status === true
    ) {
      this.setState(
        {
          userFirststname:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.email
            ) &&
            (!commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.first_name
            )
              ? this?.props?.data?.sessionData?.first_name.charAt(0)
              : this?.props?.data?.sessionData?.email.charAt(0)),
          userType:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.type
            ) && this?.props?.data?.sessionData?.type,
        },
        () => {}
      );
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
  };

  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isShowPopup: false,
    });
  };

  // componentWillReceiveProps(state, props) {
  //     this.checkSessionApi()
  // }

  onClickProfile = () => {
    // commonFunction.redirectTo('/myprofile')
    window.location.href = "/myprofile";
  };

  onClickLogout = () => {
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status === true ||
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            userFirststname: "",
            userType: "",
          });
          // location.reload()

          if (this.props?.data?.siteData?.site?.business_b2b) {
            window.location.pathname != "/login"
              ? (window.location.href = "/login")
              : null;
          } else {
            window.location.reload();
            // commonFunction.redirectTo("/");
          }
        } else {
        }
      })
      .catch((err) => {});
  };

  myBookingClickHandler = () => {
    // commonFunction.redirectTo('/myprofile', { Linkindex: 1 })
    window.location.href = `/myprofile?Linkindex=1`;
  };

  setMorePopupHandler = () => {
    this.setState({
      openMorePopup: !this.state.openMorePopup,
    });
  };

  closeTranslationModal = () => {
    this.setState({ isTranslationModal: false })
  }

  render() {
    const signupStyle = {
      border: "1px solid #3479c5",
      margin: 12,
      borderRadius: 10,
    };

    const loginStyle = {
      border: "1px solid #3479c5",
      backgroundColor: "var(--buttons_color)",
      margin: 12,
      borderRadius: 10,
    };

    const activeTabStyle = {
      backgroundColor: "#cc0000",
    };
    return (
      <>
        {/* Header for HomePage starts */}
        {/* <FBPixCodeScript /> */}
        <Analytics {...this.props?.data?.siteData?.site?.analytics_module} />

        {window.location.pathname == "/" && (
          <header
            className={`${this.props.data.cssfile.new_header_wrap}  ${
              this.state.scrolling ? this.props.data.cssfile.header_sticky : ""
            }`}
          >
            <div className={this.props.data.cssfile.header_section}>
              <div className="flex flex-row justify-between items-center container-1242 mx-auto">
                <div className={this.props.data.cssfile.header_Container}>
                  <h1>
                    <Link
                      title="Logo"
                      key={"logo"}
                      href={"/"}
                      // onclick="window.location.href='/'"
                      //onClick={() => { window.location.href = this.props.data.headermenu.supportlink }}
                      className=""
                    >
                      <img
                        className={this.props?.data?.cssfile?.header_logo}
                        src={`${
                          "/shared/api/media/" +
                          (UploadLogo?._currentValue?.isLogo
                            ? this.props?.data?.headermenu?.logo
                            : this.props?.data?.headermenu?.logo)
                        }`}
                        alt="logodata"
                      />
                    </Link>
                  </h1>

                  <h2 className={this.props.data.cssfile.header_allService}>
                    <div>
                      <div
                        className={this.props.data.cssfile.header_all_section}
                      >
                        {!commonFunction.isValueEmpty(this.props) &&
                          !commonFunction.isValueEmpty(this.props.data) &&
                          !commonFunction.isValueEmpty(
                            this.props.data.headermenu
                          ) &&
                          !commonFunction.isValueEmpty(
                            this.props.data.headermenu.menu
                          ) &&
                          this.props.data.headermenu.menu.filter(
                            (ele) => ele.status.toLowerCase() == "active"
                          )
                          .slice(0, 7).map(
                            (link, i) =>
                              link?.status.toLowerCase() === "active" && (
                                <div
                                  key={"headerMenu" + i}
                                  className={`${
                                    this.props.data.cssfile.header_sections
                                  } ${
                                    this.state.activeLink === link.text
                                      ? this.props.data.cssfile.active_hometab
                                      : ""
                                  }`}
                                >
                                  {
                                  link.text != "Offline" ? (
                                    <Link
                                      title={link.text}
                                      key={link.text}
                                      href={"/"}
                                      onClick={() => {
                                        window.location.href = link.url;
                                      }}
                                      className=""
                                    >
                                      {link.text}
                                      <img
                                        src={`${
                                          "/shared/api/media/" +
                                          this.props?.data?.siteData?.site.id +
                                          "/" +
                                          link.icon
                                        }`}
                                        alt={link.text}
                                      />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )
                          )}
                          {this.props.data.headermenu.menu.filter(
                            (ele) => ele.status.toLowerCase() == "active"
                          ).length > 7 && (
                            <MoreMenu
                              menuItems={this.props.data.headermenu.menu.slice(7)}
                              styles={this.props.data.cssfile}
                              setMoreWrapperRef={this.setMoreWrapperRef}
                              state={this.state.openMorePopup}
                              setMorePopupHandler={this.setMorePopupHandler}
                              site_id={this.props?.data?.siteData?.site.id}
                              showIcon={false}
                            />
                          )}
                      </div>
                    </div>
                  </h2>

                  <div className={this.props.data.cssfile.login_container}>
                    {/* <div className={this.props.data.cssfile.download_btn_cont}>
                      <button
                        className={this.props.data.cssfile.download_button}>
                        <img src="images/mobile.png" alt="download-app" />
                        Download App
                      </button>
                    </div> */}

                    <div
                      className={this.props.data.cssfile.suport_cont}
                      ref={this.setSupportWrapperRef}
                    >
                      <div
                        className={this.props.data.cssfile.znth_support}
                        onClick={() => {
                          this.setState({
                            openSupportPopup: !this.state.openSupportPopup,
                          });
                        }}
                      >
                        <span>Support</span>
                        <span className={this.props.data.cssfile.dropZ_arrow}>
                       
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="7"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.502 6.413.142.6h6.719L3.502 6.413z"
                            />
                          </svg>
                      
                        </span>
                      </div>
                      {this.state.openSupportPopup && (
                        <div
                          className={this.props.data.cssfile.supportdropNew}
                          style={{ display: "block" }}
                        >
                          <a
                            href={`tel:${this.props?.data?.siteData?.site?.support_number}`}
                          >
                            <div
                              className={this.props.data.cssfile.header_menuZth}
                            >
                              <img src="/images/et-hd-phn.png" alt="" />
                              {/* +971 58 953 9988 */}
                              {this.props?.data?.siteData?.site?.support_number}
                            </div>
                          </a>
                          <a
                            href={`mailto:${this.props?.data?.siteData?.site?.support_email}`}
                          >
                            <div
                              className={this.props.data.cssfile.header_menuZth}
                            >
                              <img
                                src="/images/et-hd-email.png"
                                alt=""
                                width="20px"
                                height="20px"
                              />
                              {/* connect@elevatetrips.com */}
                              {this.props?.data?.siteData?.site?.support_email}
                            </div>
                          </a>
                        </div>
                      )}
                    </div>

                    {/* <GoogleTranslate
                      style={this.props.data.cssfile.translate_element}
                    /> */}
                    <ul
                      // style={{ display: this.props?.data?.showTranslation ? "block" : "none" }}
                      className={this.props?.data?.showTranslation ? this.props.data.cssfile.navbar_right : `${this.props.data.cssfile.navbar_right} ${this.props.data.cssfile.handle_pointer}`}
                    >
                      <li className={this.props.data.cssfile.dropdown} onClick={() => this.setState({ isTranslationModal: !this.state.isTranslationModal })}>
                        <span className={this.props.data.cssfile.dropdown_span}>
                          <img
                            src="/images/india-flag.png"
                            alt="flag"
                            width="20px"
                            height="20px"
                          />
                         
                          <div className={this.props.data.cssfile.znth_main}>
                            <span>IN</span>
                            <span
                              className={this.props.data.cssfile.divider_line}
                            >
                              |
                            </span>
                            {/* <span>EN</span> */}
                            <span>{this.state.language.toUpperCase()}</span>
                            <span
                              className={this.props.data.cssfile.divider_line}
                            >
                              |
                            </span>
                            <span>INR</span>

                            {/* <span
                            ref={this.setCurrencyWrapperRef}
                            onClick={() => {this.setState({ openCurrencyPopup: true });}} 
                            className={this.props.data.cssfile.dropZ_arrow}>▾</span> */}
                            <span className={this.props?.data?.showTranslation ? this.props.data.cssfile.translate_dropdown : this.props.data.cssfile.profile_details_hide}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="7"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.502 6.413.142.6h6.719L3.502 6.413z"
                            />
                          </svg>
                          </span>
                          </div>
                          {this.state.openCurrencyPopup && (
                            <div
                              className={
                                this.props.data.cssfile.language_dropMain
                              }
                              style={{ display: "block" }}
                            >
                              <div
                                className={
                                  this.props.data.cssfile.language_mnCont
                                }
                              >
                                <div
                                  className={
                                    this.props.data.cssfile.language_wrap
                                  }
                                >
                                  <div
                                    className={
                                      this.props.data.cssfile.lang_title
                                    }
                                  >
                                    {" "}
                                    Select Language
                                  </div>
                                  <input
                                    type="radio"
                                    id="english"
                                    name="language"
                                    value="english"
                                    checked
                                  />
                                  <label
                                    className={
                                      this.props.data.cssfile.lang_label
                                    }
                                    for="english"
                                  >
                                    English
                                  </label>
                                </div>
                                <div
                                  className={
                                    this.props.data.cssfile.language_wrap
                                  }
                                >
                                  <div
                                    className={
                                      this.props.data.cssfile.lang_title
                                    }
                                  >
                                    {" "}
                                    Select Currency
                                  </div>
                                  <input
                                    type="radio"
                                    id="currency"
                                    name="currency"
                                    value="currency"
                                    checked
                                  />
                                  <label
                                    className={
                                      this.props.data.cssfile.lang_label
                                    }
                                    for="english"
                                  >
                                    INR
                                  </label>
                                </div>
                              </div>
                              <div
                                className={this.props.data.cssfile.apply_btn}
                              >
                                <button>Apply</button>
                              </div>
                            </div>
                          )}
                          {/* <img src="/theme2/down_arrow.png" className={this.props.data.cssfile.drop_arrow} alt=""/> */}
                        </span>

                       
                      </li>

                         {/* Language currency dropdown */}
                         {this.state.isTranslationModal && this.props?.data?.siteData?.site?.allow_multilingual &&
                            <GoogleTranslate
                              style={this.props.data.googleTransalationCss}
                              isMobile={false}
                              closeTranslationModal={this.closeTranslationModal.bind(this)}
                              isHomePage={this.props?.data?.siteData?.site?.allow_multilingual}
                              site_multi_languages={this.props?.data?.siteData?.site?.site_multi_languages}
                            />
                          }
                        {/* Language currency dropdown */}
                     
                    </ul>
                    
                   

                    <div
                      className={this.props.data.cssfile.afterlogin}
                      ref={this.setCalendarWrapperRef}
                      onClick={() => {
                        this.onClickArrow();
                      }}
                    >
                      {this.state.userFirststname != undefined &&
                      this.state.userFirststname != null &&
                      this.state.userFirststname != "" ? (
                        <div className={this.props.data.cssfile.afterloginNew}>
                          {" "}
                          {/* <span className={this.props.data.cssfile.user_code}>
                            {this.state.userFirststname}
                          </span> */}
                          <p style={{ fontSize: 12 }}>
                            {" "}
                            Hi {`${this.props?.data?.user?.first_name} `}
                          </p>
                          <span
                            className={this.props.data.cssfile.afterloginArrow}
                            onClick={() => {
                              this.onClickArrow();
                            }}
                          >
                            ▾
                            {/* <img src="theme2/down_arrow.png" alt="My Account" /> */}
                          </span>
                          <div
                            className={
                              this.state.isShowPopup
                                ? this.props.data.cssfile.show
                                : this.props.data.cssfile.profile_details_hide
                            }
                            onMouseLeave={() => {
                              this.onMouseLeave();
                            }}
                          >
                            {/* <div className={styles.profile_frst}> */}
                            <div
                              className={this.props.data.cssfile.profile_frst}
                            >
                              <span
                                onClick={() => {
                                  this.onClickProfile();
                                }}
                              >
                                {" "}
                                <div
                                  className={this.props.data.cssfile.menu_icon}
                                >
                                  <img
                                    type="image"
                                    src="/images/profile-01.svg"
                                    alt="profile"
                                  />
                                </div>
                                My Profile
                              </span>
                              <span onClick={this.myBookingClickHandler}>
                                {" "}
                                <div
                                  className={this.props.data.cssfile.menu_icon}
                                >
                                  <img
                                    type="image"
                                    src="/images/booking-01.svg"
                                    alt="mybooking"
                                  />
                                </div>
                                My Trips
                              </span>
                              <span
                                onClick={() => {
                                  this.onClickLogout();
                                }}
                              >
                                {" "}
                                <div
                                  className={this.props.data.cssfile.menu_icon}
                                >
                                  <img
                                    type="image"
                                    src="/images/logout-01.svg"
                                    alt="Logout"
                                  />
                                </div>
                                Logout
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={this.props.data.cssfile.login_btn}
                          // onClick={this.props.data.onClickHeaderLoginB2C}
                          onClick={() => {
                            // this.setState({ openInitialPopup: true });
                            this.props.data.onClickHeaderLogin("LogIn");
                          }}
                        >
                          <a href="#" title="My Account">
                            {/* <img
                              src="/images/up-01.svg"
                              className={this.props.data.cssfile.profile_icon}
                              alt="My Account"
                            /> */}
                            {/* <span> My Account</span> */}
                            <div
                              className={this.props.data.cssfile.Login_btn_cont}
                            >
                              <button
                                className={this.props.data.cssfile.login_button}
                              >
                                Sign In
                              </button>
                            </div>
                            {/* <img
                              src="/theme2/down_arrow.png"
                              className={this.props.data.cssfile.drop_arrow}
                              alt="My Account"
                            /> */}
                          </a>
                        </div>
                      )}

                      {this.state.openInitialPopup && (
                        <div
                          className="logindrop"
                          id="loginDrop"
                          style={{
                            display: "block",
                            width: 140,
                            borderRadius: 10,
                            top: 60,
                          }}
                        >
                          <div
                            className="ax-head-menu"
                            style={loginStyle}
                            onClick={() => {
                              this.props.data.onClickHeaderLogin("LogIn");
                              // commonFunction.redirectTo("/signin", { s: "0" });
                            }}
                          >
                            <img
                              style={{ filter: "brightness(100)" }}
                              type="image"
                              src="/images/loginew.svg"
                              alt="Sign In"
                            />
                            <span style={{ color: "white" }}>Sign In</span>
                          </div>
                          <div
                            className="ax-head-menu"
                            style={signupStyle}
                            onClick={() => {
                              this.props.data.onClickHeaderLogin("LogIn");
                              // commonFunction.redirectTo("/signin", { s: "1" });
                            }}
                          >
                            <img
                              type="image"
                              src="/images/registere.svg"
                              alt="Sign Up"
                            />
                            <span>Sign Up</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className={this.props.data.cssfile.Login_btn_cont}>
                      <button
                        className={this.props.data.cssfile.login_button}>
                      Sign In
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}

        {/* Header for HomePage ends */}

        {/* Header for other pages starts */}

        {true && window.location.pathname != "/" && (
          <header
            className={`${this.props.data.cssfile.new_header_wrap}  ${
              this.state.scrolling === true
                ? this.props.data.cssfile.header_sticky
                : ""
            }`}
          >
            <div className={this.props.data.cssfile.header_section}>
              <div className="flex flex-row justify-between items-center container-1242 mx-auto">
                <div className={this.props.data.cssfile.header_Container}>
                  <h1>
                    <Link
                      title="Logo"
                      key={"logo"}
                      // href={"/"}
                      // onclick="window.location.href='/'"
                      onClick={() => {
                        window.location.href =
                          this.props.data.headermenu.supportlink;
                      }}
                      className=""
                    >
                      <img
                        className={this.props?.data?.cssfile?.header_logo}
                        src={`${
                          "/shared/api/media/" +
                          (UploadLogo?._currentValue?.isLogo
                            ? this.props?.data?.headermenu?.logo
                            : this.props?.data?.headermenu?.logo)
                        }`}
                        alt="Logo"
                      />
                    </Link>
                  </h1>

                  <h2 className={this.props.data.cssfile.header_allService}>
                    <div>
                      <div
                        className={this.props.data.cssfile.header_all_section}
                      >
                        {!commonFunction.isValueEmpty(this.props) &&
                          !commonFunction.isValueEmpty(this.props.data) &&
                          !commonFunction.isValueEmpty(
                            this.props.data.headermenu
                          ) &&
                          !commonFunction.isValueEmpty(
                            this.props.data.headermenu.menu
                          ) &&
                          this.props.data.headermenu.menu
                            .filter(
                              (ele) => ele.status.toLowerCase() == "active"
                            )
                            .slice(0, 7)
                            .map(
                              (link, i) =>
                                link?.status.toLowerCase() === "active" && (
                                  <div
                                    key={"headerMenu" + i}
                                    className={`${
                                      this.props.data.cssfile.header_sections
                                    } ${
                                      this.props.data?.query ===
                                      link.text.toLowerCase()
                                        ? this.props.data.cssfile.active_hometab
                                        : ""
                                    }`}
                                  >
                                    {link.url.includes("https") ||
                                    link.url.includes("http") ? (
                                      <Link
                                        title={link.text}
                                        key={link.text}
                                        // href={"/"}
                                        onClick={() => {
                                          window.open(link.url, "_blank");
                                        }}
                                        className=""
                                      >
                                        {link.text}
                                        <img
                                          src={`${
                                            "/shared/api/media/" +
                                            this.props?.data?.siteData?.site
                                              .id +
                                            "/" +
                                            link.icon
                                          }`}
                                          alt={link.text}
                                        />
                                      </Link>
                                    ) : (
                                      <Link
                                        title={link.text}
                                        key={link.text}
                                        href={link.url}
                                        // onClick={() => {
                                        //   window.location.href = link.url;
                                        // }}
                                        className=""
                                      >
                                        {link.text}
                                        <img
                                          src={`${
                                            "/shared/api/media/" +
                                            this.props?.data?.siteData?.site
                                              .id +
                                            "/" +
                                            link.icon
                                          }`}
                                          alt={link.text}
                                        />
                                      </Link>
                                    )}
                                  </div>
                                )
                            )}

                        {this.props.data.headermenu.menu.filter(
                          (ele) => ele.status.toLowerCase() == "active"
                        ).length > 7 && (
                          <MoreMenu
                            menuItems={this.props.data.headermenu.menu.slice(7)}
                            styles={this.props.data.cssfile}
                            setMoreWrapperRef={this.setMoreWrapperRef}
                            state={this.state.openMorePopup}
                            setMorePopupHandler={this.setMorePopupHandler}
                            site_id={this.props?.data?.siteData?.site.id}
                            showIcon={false}
                          />
                        )}
                      </div>
                    </div>
                  </h2>
                  <div className={this.props.data.cssfile.login_container}>
                    {/* <div className={this.props.data.cssfile.download_btn_cont}>
                      <button
                        className={this.props.data.cssfile.download_button}>
                        <img src="images/mobile.png" alt="download-app" />
                        Download App
                      </button>
                    </div> */}

                    <div
                      className={this.props.data.cssfile.suport_cont}
                      ref={this.setSupportWrapperRef}
                    >
                      <div
                        className={this.props.data.cssfile.znth_support}
                        onClick={() => {
                          this.setState({
                            openSupportPopup: !this.state.openSupportPopup,
                          });
                        }}
                      >
                        <span>Support</span>
                        <span className={this.props.data.cssfile.dropZ_arrow}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="7"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.502 6.413.142.6h6.719L3.502 6.413z"
                            />
                          </svg>
                        </span>
                      </div>
                      {this.state.openSupportPopup && (
                        <div
                          className={this.props.data.cssfile.supportdropNew}
                          style={{ display: "block" }}
                        >
                          <a
                            href={`tel:${this.props?.data?.siteData?.site?.support_number}`}
                          >
                            <div
                              className={this.props.data.cssfile.header_menuZth}
                            >
                              <img src="/images/et-hd-phn.png" alt="" />
                              {/* +971 58 953 9988 */}
                              {this.props?.data?.siteData?.site?.support_number}
                            </div>
                          </a>
                          <a
                            href={`mailto:${this.props?.data?.siteData?.site?.support_email}`}
                          >
                            <div
                              className={this.props.data.cssfile.header_menuZth}
                            >
                              <img
                                src="/images/et-hd-email.png"
                                alt=""
                                width="20px"
                                height="20px"
                              />
                              {/* connect@elevatetrips.com */}
                              {this.props?.data?.siteData?.site?.support_email}
                            </div>
                          </a>
                        </div>
                      )}
                    </div>

                    <ul
                      // style={{ display: this.props?.data?.showTranslation ? "block" : "none" }}
                      className={this.props?.data?.showTranslation ? this.props.data.cssfile.navbar_right : `${this.props.data.cssfile.navbar_right} ${this.props.data.cssfile.handle_pointer}`}
                    >
                      <li className={this.props.data.cssfile.dropdown} onClick={() => this.setState({ isTranslationModal: !this.state.isTranslationModal })}>
                        <span className={this.props.data.cssfile.dropdown_span}>
                          {/* <img src="" className={this.props.data.cssfile.flag} alt=""/>  */}
                          <img
                            src="/images/india-flag.png"
                            alt="flag"
                            width="20px"
                            height="20px"
                          />
                          <div className={this.props.data.cssfile.znth_main}>
                            <span>IN</span>
                            <span
                              className={this.props.data.cssfile.divider_line}
                            >
                              |
                            </span>
                            {/* <span>EN</span> */}
                            <span>{this.state.language.toUpperCase()}</span>
                            <span
                              className={this.props.data.cssfile.divider_line}
                            >
                              |
                            </span>
                            <span>INR</span>

                            {/* <span
                            ref={this.setCurrencyWrapperRef}
                            onClick={() => {this.setState({ openCurrencyPopup: true });}} 
                            className={this.props.data.cssfile.dropZ_arrow}>▾</span> */}
                          </div>
                          <span className={this.props?.data?.showTranslation ? this.props.data.cssfile.translate_dropdown : this.props.data.cssfile.profile_details_hide}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7"
                            height="7"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3.502 6.413.142.6h6.719L3.502 6.413z"
                            />
                          </svg>
                          </span>
                          {this.state.openCurrencyPopup && (
                            <div
                              className={
                                this.props.data.cssfile.language_dropMain
                              }
                              style={{ display: "block" }}
                            >
                              <div
                                className={
                                  this.props.data.cssfile.language_mnCont
                                }
                              >
                                <div
                                  className={
                                    this.props.data.cssfile.language_wrap
                                  }
                                >
                                  <div
                                    className={
                                      this.props.data.cssfile.lang_title
                                    }
                                  >
                                    {" "}
                                    Select Language
                                  </div>
                                  <input
                                    type="radio"
                                    id="english"
                                    name="language"
                                    value="english"
                                    checked
                                  />
                                  <label
                                    className={
                                      this.props.data.cssfile.lang_label
                                    }
                                    for="english"
                                  >
                                    English
                                  </label>
                                </div>
                                <div
                                  className={
                                    this.props.data.cssfile.language_wrap
                                  }
                                >
                                  <div
                                    className={
                                      this.props.data.cssfile.lang_title
                                    }
                                  >
                                    {" "}
                                    Select Currency
                                  </div>
                                  <input
                                    type="radio"
                                    id="currency"
                                    name="currency"
                                    value="currency"
                                    checked
                                  />
                                  <label
                                    className={
                                      this.props.data.cssfile.lang_label
                                    }
                                    for="english"
                                  >
                                    INR
                                  </label>
                                </div>
                              </div>
                              <div
                                className={this.props.data.cssfile.apply_btn}
                              >
                                <button>Apply</button>
                              </div>
                            </div>
                          )}
                          {/* <img src="/theme2/down_arrow.png" className={this.props.data.cssfile.drop_arrow} alt=""/> */}
                        </span>

                        {/* Language currency dropdown */}
                        <div
                          className={this.props.data.cssfile.dropdown_menu_wrap}
                          style={{ display: "none" }}
                        >
                          <div
                            className={this.props.data.cssfile.language_drop}
                          >
                            <div
                              className={this.props.data.cssfile.language_label}
                            >
                              Language
                            </div>
                            <span
                              className={
                                this.props.data.cssfile.nation_title_wrap
                              }
                            >
                              <img
                                src=""
                                className={this.props.data.cssfile.nation_flag}
                                alt="nation flag"
                              />
                              <span
                                className={this.props.data.cssfile.nation_label}
                              >
                                India
                              </span>
                            </span>

                            <div
                              className={
                                this.props.data.cssfile.country_drop_container
                              }
                              style={{ display: "none" }}
                            >
                              <div
                                className={
                                  this.props.data.cssfile.country_scroll_box
                                }
                              >
                                <div
                                  className={
                                    this.props.data.cssfile.country_dtail_wrap
                                  }
                                >
                                  <ul>
                                    <li
                                      className={
                                        this.props.data.cssfile.country_name
                                      }
                                    >
                                      India
                                    </li>
                                    <li
                                      className={
                                        this.props.data.cssfile.country_name
                                      }
                                    >
                                      UAE
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={this.props.data.cssfile.language_drop}
                          >
                            <div
                              className={this.props.data.cssfile.language_label}
                            >
                              Currency
                            </div>
                            <span
                              className={
                                this.props.data.cssfile.nation_title_wrap
                              }
                            >
                              <img
                                src=""
                                className={this.props.data.cssfile.nation_flag}
                                alt="nation flag"
                              />
                              <span
                                className={this.props.data.cssfile.nation_label}
                              >
                                India
                              </span>
                            </span>

                            <div
                              className={
                                this.props.data.cssfile.country_drop_container
                              }
                              style={{ display: "none" }}
                            >
                              <div
                                className={
                                  this.props.data.cssfile.country_scroll_box
                                }
                              >
                                <div
                                  className={
                                    this.props.data.cssfile.country_dtail_wrap
                                  }
                                >
                                  <ul>
                                    <li
                                      className={
                                        this.props.data.cssfile.country_name
                                      }
                                    >
                                      AED
                                    </li>
                                    <li
                                      className={
                                        this.props.data.cssfile.country_name
                                      }
                                    >
                                      USD
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Language currency dropdown */}
                      </li>

                      {/* {this.state.isTranslationModal &&
                      <GoogleTranslate
                        style={this.props.data.cssfile.translate_element}
                      />
                    } */}
                        {this.state.isTranslationModal && this.props?.data?.siteData?.site?.allow_multilingual &&
                          <GoogleTranslate
                            style={this.props.data.googleTransalationCss}
                            isMobile={false}
                            closeTranslationModal={this.closeTranslationModal.bind(this)}
                            isHomePage={this.props?.data?.siteData?.site?.allow_multilingual}
                            site_multi_languages={this.props?.data?.siteData?.site?.site_multi_languages}
                        />
                        }
                    </ul>

                    <div
                      className={this.props.data.cssfile.afterlogin}
                      ref={this.setCalendarWrapperRef}
                      onClick={() => {
                        this.onClickArrow();
                      }}
                    >
                      {this.state.userFirststname != undefined &&
                      this.state.userFirststname != null &&
                      this.state.userFirststname != "" ? (
                        <div className={this.props.data.cssfile.afterloginNew}>
                          {" "}
                          {/* <span className={this.props.data.cssfile.user_code}>
                            {this.state.userFirststname}
                          </span> */}
                          <p style={{ fontSize: 12 }}>
                            {" "}
                            Hi {`${this.props?.data?.user?.first_name} `}
                          </p>
                          <span
                            className={this.props.data.cssfile.afterloginArrow}
                            onClick={() => {
                              this.onClickArrow();
                            }}
                          >
                            ▾
                            {/* <img src="theme2/down_arrow.png" alt="My Account" /> */}
                          </span>
                          <div
                            className={
                              this.state.isShowPopup
                                ? this.props.data.cssfile.show
                                : this.props.data.cssfile.profile_details_hide
                            }
                            onMouseLeave={() => {
                              this.onMouseLeave();
                            }}
                          >
                            {/* <div className={styles.profile_frst}> */}
                            <div
                              className={this.props.data.cssfile.profile_frst}
                            >
                              <span
                                onClick={() => {
                                  this.onClickProfile();
                                }}
                              >
                                {" "}
                                <div
                                  className={this.props.data.cssfile.menu_icon}
                                >
                                  <img
                                    type="image"
                                    src="/images/profile-01.svg"
                                    alt="profile"
                                  />
                                </div>
                                My Profile
                              </span>
                              <span onClick={this.myBookingClickHandler}>
                                {" "}
                                <div
                                  className={this.props.data.cssfile.menu_icon}
                                >
                                  <img
                                    type="image"
                                    src="/images/booking-01.svg"
                                    alt="mybooking"
                                  />
                                </div>
                                My Trips
                              </span>
                              <span
                                onClick={() => {
                                  this.onClickLogout();
                                }}
                              >
                                {" "}
                                <div
                                  className={this.props.data.cssfile.menu_icon}
                                >
                                  <img
                                    type="image"
                                    src="/images/logout-01.svg"
                                    alt="Logout"
                                  />
                                </div>
                                Logout
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={this.props.data.cssfile.login_btn}
                          // onClick={this.props.data.onClickHeaderLoginB2C}
                          onClick={() => {
                            // this.setState({ openInitialPopup: true });
                            this.props.data.onClickHeaderLogin("LogIn");
                          }}
                        >
                          <a href="#" title="My Account">
                            {/* <img
                              src="/images/up-01.svg"
                              className={this.props.data.cssfile.profile_icon}
                              alt="My Account"
                            /> */}
                            {/* <span> My Account</span> */}
                            <div
                              className={this.props.data.cssfile.Login_btn_cont}
                            >
                              {window.location.pathname != "/signin" && (
                                <button
                                  className={
                                    this.props.data.cssfile.login_button
                                  }
                                >
                                  Sign In
                                </button>
                              )}
                            </div>
                            {/* <img
                              src="/theme2/down_arrow.png"
                              className={this.props.data.cssfile.drop_arrow}
                              alt="My Account"
                            /> */}
                          </a>
                        </div>
                      )}

                      {this.state.openInitialPopup && (
                        <div
                          className="logindrop"
                          id="loginDrop"
                          style={{
                            display: "block",
                            width: 140,
                            borderRadius: 10,
                            top: 60,
                          }}
                        >
                          <div
                            className="ax-head-menu"
                            style={loginStyle}
                            onClick={() => {
                              this.props.data.onClickHeaderLogin("LogIn");
                              // commonFunction.redirectTo("/signin", { s: "0" });
                            }}
                          >
                            <img
                              style={{ filter: "brightness(100)" }}
                              type="image"
                              src="/images/loginew.svg"
                              alt="Sign In"
                            />
                            <span style={{ color: "white" }}>Sign In</span>
                          </div>
                          <div
                            className="ax-head-menu"
                            style={signupStyle}
                            onClick={() => {
                              this.props.data.onClickHeaderLogin("LogIn");
                              // commonFunction.redirectTo("/signin", { s: "1" });
                            }}
                          >
                            <img
                              type="image"
                              src="/images/registere.svg"
                              alt="Sign Up"
                            />
                            <span>Sign Up</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className={this.props.data.cssfile.Login_btn_cont}>
                      <button
                        className={this.props.data.cssfile.login_button}>
                      Sign In
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}

        {/* Header for other pages ends */}
      </>
    );
  }
}

export default Header5;
